<template>
  <div id="id">
    <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/forum' }">
          论坛
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="router-link" :to="{ path: '/AllGallery' }"> 画廊 </b-navbar-item>

      </template>
    </b-navbar>
    <b-button
      size="large"
      icon-left="message"
      type="is-warning"
      :rounded="isRounded"
      style="
        position: fixed;
        top: 50px;
        right: 50px;
        z-index: 2000;
        transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -webkit-transform: scale(1.2, 1.2);
      "
      @click="show"
    ></b-button>

    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Forum</h1>
          <h2 class="subtitle">看看群友的迷惑言论？</h2>
          <!-- <b-button
            type="is-text"
            tag="router-link"
            :to="{ path: '/AllGallery' }"
            >图床</b-button
          > -->
        </div>
      </div>
    </section>
    <div
      class="content"
      style="
        padding-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
        padding-buttom: 20px;
      "
    >
      <!-- <article class="media">
        <figure class="media-left">
          <p class="image is-64x64">
            <img src="https://bulma.io/images/placeholders/128x128.png" />
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p>
              <strong>Barbara Middleton</strong>
              <br />
              
              <br />
              <small><a>Like</a> · <a>Reply</a> · 3 hrs</small>
            </p>
          </div>
        </div>
      </article> -->
      <div class="tile is-ancestor">
        <div
          class="tile is-4 is-vertical is-parent"
          v-if="articles && articles.length >= 2"
        >
          <div class="tile is-child box" v-if="articles && articles.length >= 2">
            <b-tooltip
              :label="postinfo"
              type="is-light"
              :delay="250"
              position="is-top"
            >
              <p @mouseover="trigger(0)" class="title" @click="into(0)">
                {{ articles[0].raw.title }}
              </p>
            </b-tooltip>
            <br />
            <b-tooltip type="is-light" position="is-right">
              <template v-slot:content style="max-width: 400px">
                <div v-for="item in articles[0].replys" :key="item">
                  <small>回复:{{ item.text.substr(0, 100) }}</small>
                </div>
              </template>
              <p>
                {{ articles[0].raw.text.substr(0, 300) }}
              </p>
            </b-tooltip>
          </div>
          <div class="tile is-child box" v-if="articles && articles.length >= 3">
            <b-tooltip
              :label="postinfo"
              type="is-light"
              :delay="250"
              position="is-top"
            >
              <p @mouseover="trigger(1)" class="title" @click="into(1)">
                {{ articles[1].raw.title }}
              </p>
            </b-tooltip>
            <br />
            <b-tooltip type="is-light" position="is-right">
              <template v-slot:content style="max-width: 400px">
                <div v-for="item in articles[1].replys" :key="item">
                  <small>回复:{{ item.text.substr(0, 100) }}</small>
                </div>
              </template>
              <p>
                {{ articles[1].raw.text.substr(0, 300) }}
              </p>
            </b-tooltip>
          </div>
        </div>
        <div class="tile is-parent" v-if="articles && articles.length >= 1">
          <div class="tile is-child box">
            <b-tooltip
              :label="postinfo"
              type="is-light"
              :delay="250"
              position="is-top"
            >
              <p @mouseover="trigger(-1)" class="title" @click="into(-1)">
                {{ articles[articles.length - 1].raw.title }}
              </p>
            </b-tooltip>
            <b-tooltip type="is-light" position="is-left">
              <template v-slot:content style="max-width: 400px">
                <div
                  v-for="item in articles[articles.length - 1].replys"
                  :key="item"
                >
                  <small>回复:{{ item.text.substr(0, 100) }}</small>
                </div>
              </template>
              <p>
                {{ articles[articles.length - 1].raw.text.substr(0, 800) }}
              </p>
            </b-tooltip>
          </div>
        </div>
      </div>
      <div class="tile is-ancestor" v-if="articles.length >= 4">
        <div class="tile is-parent" v-if="articles.length >= 4">
          <div class="tile is-child box">
            <b-tooltip
              :label="postinfo"
              type="is-light"
              :delay="250"
              position="is-top"
            >
              <p @mouseover="trigger(2)" class="title" @click="into(2)">
                {{ articles[2].raw.title }}
              </p>
            </b-tooltip>
            <b-tooltip type="is-light" position="is-right">
              <template v-slot:content style="max-width: 400px">
                <div v-for="item in articles[2].replys" :key="item">
                  <small>回复:{{ item.text.substr(0, 100) }}</small>
                </div>
              </template>
              <p>
                {{ articles[2].raw.text.substr(0, 50) }}
              </p>
            </b-tooltip>
            <b-image
              v-if="articles[2].raw.media && articles[2].raw.media.length > 0"
              :src="articles[2].raw.media[0].url"
              webp-fallback=".jpg"
            ></b-image>
          </div>
        </div>
        <div class="tile is-parent" v-if="articles.length >= 5">
          <div class="tile is-child box">
            <b-tooltip
              :label="postinfo"
              type="is-light"
              :delay="250"
              position="is-top"
            >
              <p @mouseover="trigger(3)" class="title" @click="into(3)">
                {{ articles[3].raw.title }}
              </p>
            </b-tooltip>
            <b-tooltip type="is-light" position="is-right">
              <template v-slot:content style="max-width: 400px">
                <div v-for="item in articles[3].replys" :key="item">
                  <small>回复:{{ item.text.substr(0, 100) }}</small>
                </div>
              </template>
              <p>
                {{ articles[3].raw.text.substr(0, 50) }}
              </p>
            </b-tooltip>
            <b-image
              v-if="articles[3].raw.media && articles[3].raw.media.length > 0"
              :src="articles[3].raw.media[0].url"
              webp-fallback=".jpg"
            ></b-image>
          </div>
        </div>
        <div
          class="tile is-4 is-vertical is-parent"
          v-if="articles.length >= 6"
        >
          <div class="tile is-child box" v-if="articles.length >= 6">
            <b-tooltip
              :label="postinfo"
              type="is-light"
              :delay="250"
              position="is-top"
            >
              <p @mouseover="trigger(4)" class="title" @click="into(4)">
                {{ articles[4].raw.title }}
              </p>
            </b-tooltip>
            <b-tooltip type="is-light" position="is-left">
              <template v-slot:content style="max-width: 400px" v-if="articles[4].replys">
                <div v-for="item in articles[4].replys" :key="item">
                  <small>回复:{{ item.text.substr(0, 100) }}</small>
                </div>
              </template>
              <p>
                {{ articles[4].raw.text.substr(0, 50) }}
              </p>
            </b-tooltip>
            <b-image
              v-if="articles[4].raw.media && articles[4].raw.media.length > 0"
              :src="articles[4].raw.media[0].url"
              webp-fallback=".jpg"
            ></b-image>
          </div>
        </div>
      </div>
      <div class="tile is-ancestor" v-if="articles.length >= 7">
        <div class="tile is-parent" v-if="articles.length >= 7">
          <div class="tile is-child box">
            <b-tooltip
              :label="postinfo"
              type="is-light"
              :delay="250"
              position="is-top"
            >
              <p @mouseover="trigger(5)" class="title" @click="into(5)">
                {{ articles[5].raw.title }}
              </p>
            </b-tooltip>
            <b-tooltip type="is-light" position="is-right">
              <template v-slot:content style="max-width: 400px">
                <div v-for="item in articles[5].replys" :key="item">
                  <small>回复:{{ item.text.substr(0, 100) }}</small>
                </div>
              </template>
              <p>
                {{ articles[5].raw.text.substr(0, 800) }}
              </p>
            </b-tooltip>
          </div>
        </div>
        <div class="tile is-parent" v-if="articles.length >= 8">
          <div class="tile is-child box">
            <b-tooltip
              :label="postinfo"
              type="is-light"
              :delay="250"
              position="is-top"
            >
              <p @mouseover="trigger(6)" class="title" @click="into(6)">
                {{ articles[6].raw.title }}
              </p>
            </b-tooltip>
            <b-tooltip type="is-light" position="is-left">
              <template v-slot:content style="max-width: 400px">
                <div v-for="item in articles[6].replys" :key="item">
                  <small>回复:{{ item.text.substr(0, 100) }}</small>
                </div>
              </template>
              <p>
                {{ articles[6].raw.text.substr(0, 800) }}
              </p>
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>

    <section
      style="
        padding-top: 0px;
        padding-bottom: 1cm;
        padding-left: 50px;
        padding-right: 50px;
      "
    >
      <b-pagination
        :total="total"
        v-model="current"
        :order="order"
        :size="size"
        :simple="isSimple"
        :rounded="isRounded"
        :per-page="perPage"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        @change="nextpage"
      >
      </b-pagination>
    </section>
    <!-- <section>
      <b-modal v-model="writePost" :width="640" @close="close">
        <b-field label="Title" label-position="inside">
          <b-input value="" v-model="title"></b-input>
        </b-field>
        <mavon-editor
          ref="md"
          v-model="text"
          @imgAdd="$imgAdd"
          boxShadow="false"
          style="z-index: 0; min-height: 500px"
          placeholder="请输入正文"
        />
        <div class="buttons">
          <b-button type="is-primary" @click="goPost" expanded>回复</b-button>
        </div>
      </b-modal>
    </section> -->
  </div>
</template>

<script>
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css"; // 未导入该样式会出现工具栏无法正常显示的问题

export default {
  components: {
    mavonEditor,
  },
  data() {
    return {
      email: "",
      labelPosition: "on-border",
      articles: null,
      total: 0,
      current: 1,
      perPage: 8,
      rangeBefore: 3,
      rangeAfter: 1,
      order: "",
      size: "",
      postinfo: "",
      isSimple: false,
      isRounded: true,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      writePost: false,
      text: "",
    };
  },
  methods: {
    show() {
      this.$router.push("/WritePost");
    },
    into(id) {
      if (id == -1) {
        id = this.articles.length - 1;
      }
      this.$router.push({
        name: "Post",
        query: {
          pid: this.articles[id].pid,
        },
      });
    },
    async nextpage(val) {
      let res = await this.$http.get("/api/posts/p/" + val.toString(), {
        headers: {
          pass: "",
          Authorization: "Bearer " + (await localStorage.getItem("token")),
        },
      });
      this.articles = res.data.articles;
      this.articles.sort((a, b) => {
        // console.log(a.raw.text.length - b.raw.text.length);
        return a.raw.text.length - b.raw.text.length;
      });
    },
    async trigger(id) {
      //多次访问需要缓存
      if (id == -1) {
        id = this.articles.length - 1;
      }
      let cache = await localStorage.getItem(this.articles[id].pid.toString());
      try {
        cache = JSON.parse(cache);
        console.log(cache);
      } catch (error) {
        console.log(error);
        cache = null;
      }
      if (cache && cache.expire > new Date().getTime() - 10000) {
        this.postinfo = "发帖:" + cache.reply;
        return;
      } else {
        let res = await this.$http.get(
          "/api/posts/preview/" + this.articles[id].pid,
          {
            headers: {
              pass: "",
              Authorization: "Bearer " + (await localStorage.getItem("token")),
            },
          }
        );
        this.postinfo = "回帖:" + res.data.reply;
        localStorage.setItem(
          this.articles[id].pid,
          JSON.stringify({
            reply: res.data.reply,
            expire: new Date().getTime(),
          })
        );
      }
    },
  },
  async beforeCreate() {
    this.email = await localStorage.getItem("email");
    let res = await this.$http.get("/api/posts/p/1", {
      headers: {
        pass: "",
        Authorization: "Bearer " + (await localStorage.getItem("token")),
      },
    });
    // console.log(res.data);
    this.articles = res.data.articles;
    this.articles.sort((a, b) => {
      // console.log(a.raw.text.length - b.raw.text.length);
      return a.raw.text.length - b.raw.text.length;
    });
    console.log(this.articles);
    res = await this.$http.get("/api/posts/count");
    this.total = +res.data;
    this.perPage = 8;
    // console.log(res.data);
  },
};
</script>

<style>
body {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(118,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%230FF'/%3E%3Cstop offset='1' stop-color='%23CF6'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(244,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23F00'/%3E%3Cstop offset='1' stop-color='%23FC0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='9.899999999999999'%3E%3Cpath transform='translate(-41.3 4.4) rotate(1.4 1409 581) scale(0.99246)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='3.3000000000000003' transform='translate(-42.5 29) rotate(4.2 800 450) scale(1.00596)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(11.7 -46.5) rotate(37.5 401 736) scale(1.00596)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='3'%3E%3Cpath transform='translate(174 -2.5999999999999996) rotate(0.6499999999999999 150 345) scale(0.9888199999999999)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='6.6000000000000005' transform='translate(-66.5 -77) rotate(39.6 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='translate(-123.6 36.4) rotate(6.6 1400 132) scale(0.955)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
</style>